<template>
  <div class="page-wrap">
    <div class="main-page-column pt-0">
      <user-add-dialog
        :open="userDialog"
        :edited-item="editedUserItem"
        :edit-mode="true"
        @close-user-dialog="close"
      ></user-add-dialog>
      <v-dialog v-model="passResetDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline primary--text mb-3"
            >Reset Password</v-card-title
          >
          <v-card-text>
            <p>
              This will send an email to the user with instrunctions to reset
              the password.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey darken-1" text @click="passResetDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="sendPasswordReset">Reset</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="resendInvitationDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline primary--text mb-3"
            >Resend Invitation Link</v-card-title
          >
          <v-card-text>
            <p>
              This will resend an email to the user with instructions to setup an account.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey darken-1" text @click="resendInvitationDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="sendInvitationLink">Resend</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="scroll-container pt-0">
        <v-row v-if="user" no-gutters wrap style="height: 100%;">
          <v-col cols="12" md="4" lg="4" xl="3">
            <div class="page-wrap">
              <div class="main-page-column px-3">
                <v-card v-if="!$apollo.loading" flat class="mx-auto">
                  <v-card-title>{{
                    user.firstName + ' ' + user.lastName
                  }}</v-card-title>
                  <v-card-text>{{ user.email }}</v-card-text>
                  <v-card-text>
                    <v-chip x-small class="mr-3">{{ user.userRole }}</v-chip>
                    <v-chip
                      v-if="user.isAdmin"
                      color="info lighten-2 font-weight-bold upperCaseSpacing"
                      x-small
                      >admin</v-chip
                    >
                    <v-chip
                      v-if="user.isSuperAdmin"
                      color="info lighten-2 font-weight-bold upperCaseSpacing"
                      x-small
                      >superadmin</v-chip
                    >
                    <div>
                      <span
                        v-if="user.status === 'active'"
                        class="success--text font-weight-medium"
                        >{{ user.status }}</span
                      >
                      <span
                        v-else-if="user.status === 'suspended'"
                        class="error--text font-weight-medium"
                        >{{ user.status }}</span
                      >
                      <span
                        v-else
                        class="grey--text text--darken-4 font-weight-medium"
                        >{{ user.status }}</span
                      >
                    </div>
                    <div>
                      Last sign in:
                      <span v-if="user.lastLogin">{{
                        user.lastLogin | moment('calendar')
                      }}</span>
                      <span v-else>Never</span>
                    </div>
                    <div>
                      Created: {{ user.createdAt | moment('D MMM YYYY') }}
                    </div>
                    <v-divider class="my-4"></v-divider>
                    <div>Department</div>
                    <div class="grey--text text--darken-4">
                      {{ user.department.name }}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text small @click="editItem(user)">
                      <v-icon left>mdi-pencil-outline</v-icon>edit
                    </v-btn>
                    <v-btn v-if="user.status === 'pending'" text small @click="resendInvitationDialog = true">
                      <v-icon left>mdi-email-outline</v-icon>resend invitation
                    </v-btn>
                    <v-btn v-else text small @click="passResetDialog = true">
                      <v-icon left>mdi-lock-outline</v-icon>reset password
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="8" xl="9">
            <div class="page-wrap">
              <div class="main-page-column px-3">
                <div class="scroll-container pa-0">
                  <v-row no-gutters class="px-2">
                    <v-col>
                      <h3 class="mb-3">Apps</h3>
                    </v-col>
                    <v-col>
                      <v-row no-gutters justify="end">
                        <!-- <user-app-dialog :user="user"></user-app-dialog> -->
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-expansion-panels
                    v-model="panel"
                    multiple
                    flat
                    :disabled="user.status === 'suspended'"
                  >
                    <v-expansion-panel
                      v-for="(item, idx) in userApps"
                      :key="item.id"
                    >
                      <v-expansion-panel-header>
                        <v-row no-gutters>
                          <v-col cols="8" class="pa-0">
                            <div class="mb-2 text-truncate">
                              {{ item.name }}
                            </div>
                            <div class="grey--text caption text-truncate">
                              {{ item.description }}
                            </div>
                          </v-col>
                          <v-spacer class="pa-0"></v-spacer>
                          <v-col class="pa-0 pr-6">
                            <v-row justify="end">
                              <v-switch
                                v-show="item.name !== 'Admin'"
                                v-model="item.active"
                                class="ma-0 mt-2 pa-0"
                                input-value="true"
                                hide-details
                                @click.native.stop="activateApp(item, idx)"
                              ></v-switch>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-list>
                          <v-list-item
                            v-for="scope in orderBy(item.scopes, 'name')"
                            :key="scope.id"
                            two-line
                          >
                            <v-list-item-content class="py-0">
                              <v-row>
                                <v-col cols="12" class="mb-0"
                                  ><v-divider class="my-0"></v-divider
                                ></v-col>
                                <v-col>
                                  <v-list-item-title>{{
                                    scope.name
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle>{{
                                    scope.description
                                  }}</v-list-item-subtitle>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="4" lg="3" xl="3">
                                  <v-select
                                    v-model="scope.accessLevel"
                                    :items="accessLevels"
                                    item-text="name"
                                    item-value="id"
                                    max-height="auto"
                                    autocomplete
                                    hide-details
                                    required
                                    class="mt-0 pt-0"
                                    :disabled="
                                      !item.active && item.name !== 'Admin'
                                    "
                                    @change="changeScope(scope)"
                                  >
                                    <template v-slot:item="data">
                                      <v-list-item-content>
                                        <v-list-item-subtitle
                                          class="font-weight-bold"
                                          >{{
                                            data.item.name
                                          }}</v-list-item-subtitle
                                        >
                                        <v-list-item-subtitle
                                          class="font-weight-regular caption"
                                          >{{
                                            data.item.description
                                          }}</v-list-item-subtitle
                                        >
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-content>
                      <v-divider></v-divider>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import USER_APP_LIST from '@/graphql/UserApps.gql'
import USER from '@/graphql/User.gql'
import USER_APP_UPDATE from '@/graphql/UserAppUpdate.gql'
import RESET_PASSWORD from '@/graphql/PasswordReset.gql'
import UserAddDialog from '@/modules/admin/components/core/UserAddDialog.vue'
import USER_SCOPE_UPDATE from '@/graphql/UserScopeUpdate.gql'
import RESEND_INVITATION_LINK from '@/graphql/InvitationLinkResend.gql'

export default {
  name: 'UserDetail',
  components: {
    UserAddDialog
  },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    panel: [],
    passResetDialog: false,
    resendInvitationDialog: false,
    userDialog: false,
    search: '',
    editedUserItem: {
      firstName: '',
      lastName: '',
      email: '',
      userRole: 'user',
      isAdmin: false,
      status: 'active'
    },
    accessLevels: [
      { id: 0, name: 'None', description: 'No access' },
      { id: 1, name: 'Read', description: 'Read access only' },
      {
        id: 2,
        name: 'Read and Write',
        description: 'Full read and write access'
      }
      // {
      //   id: 'full',
      //   name: 'Full',
      //   description: 'full access to read, write and manage'
      // }
    ],
    requiredRule: [
      v => !!v.id || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  apollo: {
    user: {
      query: USER,
      variables() {
        return {
          id: this.$route.params.id
        }
      },
      update(data) {
        return data.user
      }
    },
    availableapps: {
      query: USER_APP_LIST,
      variables() {
        return {
          userID: this.$route.params.id
        }
      },
      update(data) {
        return data.userApps
      }
    }
  },

  computed: {
    userApps() {
      if (this.user && (this.user.isAdmin || this.user.isSuperAdmin)) {
        return this._.orderBy(this.availableapps, ['name'], ['asc'])
      } else {
        let sorted = this._.reject(this.availableapps, { name: 'Admin' })
        return this._.orderBy(sorted, ['name'], ['asc'])
      }
    },
    currentUser() {
      return this.$store.state.currentUser
    }
  },

  methods: {
    close(success) {
      this.userDialog = false
      // Show successfully saved
      if (success) {
        this.snackbarText = 'User Updated'
        this.snackbar = true
      }
    },

    sendInvitationLink() {
    this.$apollo
      .mutate({
        mutation: RESEND_INVITATION_LINK,
        variables: {
          input: {
            email: this.user.email
          }
        }
      })
      .then(() => {
        this.resendInvitationDialog = false

        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Invitatation Link Sent'
        })
      })
      .catch(() => {
        this.resendInvitationDialog = false

        this.$store.commit('showSnackbar', {
          active: true,
          message: 'A general system error occured'
        })
      })
    },

    sendPasswordReset() {
      this.$apollo
        .mutate({
          mutation: RESET_PASSWORD,
          variables: {
            input: {
              email: this.user.email
            }
          }
        })
        .then(() => {
          this.passResetDialog = false

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Password Reset'
          })
        })
        .catch(() => {
          this.passResetDialog = false

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'A general system error occured'
          })
        })
    },

    editItem(item) {
      this.editedUserItem = this._.cloneDeep(item)
      this.userDialog = true
    },

    deleteItem(item) {
      const index = this.users.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.users.splice(index, 1)
    },

    formatDate(item) {
      return this.$moment(item).format('d MMM YYYY HH:mm')
    },

    calendarDate(item) {
      return (
        this.$moment(item)
          // .format('d MMM YYYY HH:mm')
          .calendar()
      )
    },
    activateApp(item, idx) {
      let data = {
        id: item.id,
        appID: item.appID,
        userID: item.userID,
        active: item.active
      }
      this.$apollo
        .mutate({
          mutation: USER_APP_UPDATE,
          variables: {
            input: data
          }
        })
        .then(result => {
          let data = result.data.updateUserApp
          if (data.active) {
            this.$store.commit('showSnackbar', {
              active: true,
              message: data.name + ' activated'
            })
            this.panel.push(idx)
          } else {
            this.$store.commit('showSnackbar', {
              active: true,
              message: data.name + ' disabled'
            })
            // this.snackbarText = data.name + ' disabled'
            let index = this.panel.indexOf(idx)
            this.panel.splice(index, 1)
          }
        })
    },
    changeScope(item) {
      let data = {
        id: item.id,
        userAppID: item.userAppID,
        appScopeID: item.appScopeID,
        accessLevel: item.accessLevel
      }

      this.$apollo
        .mutate({
          mutation: USER_SCOPE_UPDATE,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Access Updated'
          })

          this.$apollo
            .query({
              query: USER_APP_LIST,
              fetchPolicy: 'network-only',
              variables: {
                userID: this.currentUser.id
              }
            })
            .then(res => {
              this.$store.commit('SET_USER_APPS', res.data.userApps)
            })
        })
    }
  }
  // created () {
  //   this.$moment.locale('nl', null)
  // }
}
</script>

<style></style>
