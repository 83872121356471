<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <!-- <template v-slot:activator="{ on }">
      <v-btn color="primary" dark depressed v-on="on">add user</v-btn>
    </template>-->
    <v-card v-if="item">
      <v-card-title>
        <span class="headline">{{ title(item.auditType) }} Level</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert type="info" outlined>
              Make sure no sequence overlaps as this may cause some unexpected
              side effects.
              <br />
              The last level should always have and open ending. To do this set
              the to column to 0.
            </v-alert>
          </v-col>
        </v-row>
        <v-form
          ref="auditFlowLevelForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-col cols="12" class="py-0">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12" class="pb-2">
                    <label>From</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-currency-field
                      v-if="defaultCurrency"
                      v-model="item.from"
                      autocomplete="off"
                      outlined
                      :prefix="defaultCurrency.symbol"
                      dense
                    ></v-currency-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12" class="pb-2">
                    <label>To</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-currency-field
                      v-if="defaultCurrency"
                      v-model="item.to"
                      autocomplete="off"
                      :prefix="defaultCurrency.symbol"
                      outlined
                      :default-value="100"
                      dense
                    ></v-currency-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="authorisers">
              <v-col cols="12" class="pb-0">
                <div class="subtitle-2">Authorisation Steps</div>
              </v-col>
              <v-col>
                <v-row v-for="(data, index) in item.auditors" :key="index">
                  <v-col cols="1" class="pt-5">{{ index + 1 }}</v-col>
                  <v-col cols="9">
                    <v-select
                      v-model="data.userID"
                      :items="authorisers"
                      :rules="requiredRule"
                      autocomplete
                      required
                      item-value="id"
                      :disabled="$apollo.loading"
                      hide-details
                      class="pt-0"
                      @change="changeItem(data, index)"
                    >
                      <template v-slot:selection="subData">{{
                        subData.item.firstName + ' ' + subData.item.lastName
                      }}</template>
                      <template v-slot:item="{ item }">
                        <span @click="search = null">{{
                          item.firstName + ' ' + item.lastName
                        }}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="error"
                      absolute
                      right
                      fab
                      small
                      icon
                      elevation="0"
                      @click="removeFlowStep(index)"
                    >
                      <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mt-3">
                <v-btn
                  color="info"
                  outlined
                  rounded
                  x-small
                  @click="addFlowStep"
                  >add step</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          rounded
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="info"
          small
          rounded
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AUDIT_FLOW_TEMPLATE_CREATE from '@/graphql/AuditFlowTemplateCreate.gql'
import AUDIT_FLOW_TEMPLATE_UPDATE from '@/graphql/AuditFlowTemplateUpdate.gql'
import AUDIT_FLOW_TEMPLATES from '@/graphql/AuditFlowTemplates.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import USER_LIST from '@/graphql/Users.gql'

export default {
  name: 'AuditFlowLevelEditDialog',
  props: {
    item: { type: Object, default: null },
    open: Boolean,
    edit: Boolean
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    auditors: [],
    auditor: {
      userID: '',
      firstName: '',
      lastName: '',
      email: '',
      sequence: 0,
      nextAuditor: null
    }
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        // if (val) {
        //   return true
        // }
        return val
      }
    },
    defaultCurrency() {
      return this._.find(this.currencies, function(item) {
        return item.defaultCurrency === true
      })
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    authorisers() {
      return this._.filter(this.users, function(item) {
        return (
          (item.userRole === 'director' || item.userRole === 'seniormanager') &&
          !item.isSuperAdmin
        )
      })
    }
  },
  apollo: {
    currencies: CURRENCY_LIST,
    users: USER_LIST
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    title(type) {
      switch (type) {
        case 'BUDGET':
          return 'Budget'

        case 'PROJECT':
          return 'Project'

        case 'PURCHASEORDER':
          return 'Purchase Order'

        case 'PRODUCTIONORDER':
          return 'Production'

        default:
          break
      }
    },
    addFlowStep() {
      let auditor = {
        userID: '',
        firstName: '',
        lastName: '',
        email: '',
        sequence: this.item.auditors.length + 1 || 1,
        nextAuditor: null
      }

      this.item.auditors.push(auditor)
    },
    removeFlowStep(index) {
      this.item.auditors.splice(index, 1)
    },
    changeItem(item, index) {
      // let lastAuditor = this._.findLast(this.user.auditors)

      if (this.auditors.length > 1) {
        let idx = index - 1
        this.auditors[idx].nextAuditor = item.userID
      }
    },

    close(success) {
      // if (!this.editMode) {
      //   this.$refs.userForm.reset()
      // }

      this.$emit('close-audit-flow-level-dialog', success)
    },

    save() {
      var auditors = []
      this._.forEach(this.item.auditors, function(item, idx) {
        let auditor = {
          id: item.id || null,
          userID: item.userID,
          sequence: idx + 1,
          nextAuditor: item.nextAuditor || null
        }

        auditors.push(auditor)
      })
      // Save new item
      if (this.edit) {
        let item = {
          id: this.item.id,
          auditType: this.item.auditType,
          from: this.item.from * 100,
          to: this.item.to * 100,
          auditors: auditors
        }
        // validate
        if (this.$refs.auditFlowLevelForm.validate()) {
          this.$apollo
            .mutate({
              mutation: AUDIT_FLOW_TEMPLATE_UPDATE,
              variables: {
                input: item
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        let item = {
          id: this.item.id,
          auditType: this.item.auditType,
          from: this.item.from * 100,
          to: this.item.to * 100,
          auditors: auditors
        }
        // validate
        if (this.$refs.auditFlowLevelForm.validate()) {
          this.$apollo
            .mutate({
              mutation: AUDIT_FLOW_TEMPLATE_CREATE,
              variables: {
                input: item
              },
              update: (store, { data: { createAuditFlowTemplate } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: AUDIT_FLOW_TEMPLATES
                })
                // Add our requirement from the mutation to the end
                data.auditFlowTemplates.push(createAuditFlowTemplate)
                // Write our data back to the cache.
                store.writeQuery({ query: AUDIT_FLOW_TEMPLATES, data })
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      }
    }
  }
}
</script>

<style></style>
