<template>
  <div class="app-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'DepartmentLayout'
}
</script>

<style></style>
