<template>
  <div class="page-wrap">
    <div class="page-header pt-0 px-1">
      <v-toolbar color="transparent" flat class="mb-3 b-radius">
        <!-- <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>-->
        <v-btn color="primary" rounded @click="newItem">add user</v-btn>
        <user-add-dialog
          :open="dialog"
          :edited-item="editedItem"
          :edit-mode="edit"
          @close-user-dialog="close"
        ></user-add-dialog>

        <v-text-field
          v-model="search"
          hide-details
          solo
          flat
          dense
          clearable
          placeholder="Search User"
          prepend-inner-icon="mdi-magnify"
          class="xs12 sm4 md3 ml-5"
        ></v-text-field>

        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-card class="b-radius pa-3" flat>
          <v-data-table
            :headers="headers"
            :items="filteredList"
            :search="search"
            :items-per-page="pageLength"
            :hide-default-footer="filteredList.length < pageLength"
            :mobile-breakpoint="null"
            class="table-light"
          >
            <template v-slot:item.firstName="{ item }">
              <router-link
                :to="{ name: 'UserDetail', params: { id: item.id } }"
                class="fancy-link text-truncate"
                >{{ item.firstName + ' ' + item.lastName }}</router-link
              >
            </template>
            <template v-slot:item.isAdmin="{ item }">
              <v-chip
                v-if="item.isAdmin"
                color="info lighten-2 font-weight-bold upperCaseSpacing"
                x-small
                class="mr-2"
                >admin</v-chip
              >
              <v-chip
                v-if="item.isSuperAdmin"
                color="warning font-weight-bold upperCaseSpacing"
                x-small
                >superadmin</v-chip
              >
            </template>
            <template v-slot:item.lastName="{}"></template>
            <template v-slot:item.lastLogin="{ item }">
              <span v-if="item.lastLogin" class=" text-truncate">{{
                calendarDate(item.lastLogin)
              }}</span>
              <span v-else>never</span>
            </template>
            <template v-slot:item.reportsTo="{ item }">
              <router-link
                :to="{ name: 'UserDetail', params: { id: item.reportsTo.id } }"
                class="fancy-link text-truncate"
                >{{
                  item.reportsTo.firstName + ' ' + item.reportsTo.lastName
                }}</router-link
              >
            </template>
            <template v-slot:item.status="{ item }">
              {{ item.status }}
              <span v-if="item.status === 'suspended'"
                >({{ formatDate(item.updatedAt) }})</span
              >
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                color="grey"
                rounded
                outlined
                x-small
                class="mr-2"
                @click.stop="editItem(item)"
              >
                quick edit
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import USERS from '@/graphql/Users.gql'
import UserAddDialog from '@/modules/admin/components/core/UserAddDialog.vue'

export default {
  name: 'UserList',

  components: {
    UserAddDialog
  },
  data: () => ({
    pageLength: 10,
    dialog: false,
    search: '',
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'firstName'
      },
      { text: '', value: 'lastName', visible: false, sortable: false },
      { text: 'Email', align: 'left', value: 'email', sortable: false },
      {
        text: 'Department',
        align: 'left',
        value: 'department.name',
        sortable: false
      },
      { text: 'Role', align: 'left', value: 'userRole', sortable: true },
      { text: '', align: 'left', value: 'isAdmin', sortable: false },
      {
        text: 'Reports to',
        align: 'left',
        value: 'reportsTo',
        sortable: false
      },
      { text: 'Status', align: 'left', value: 'status', sortable: true },
      { text: 'Last Login', align: 'left', value: 'lastLogin' },
      { text: '', align: 'end', value: 'action', sortable: false }
    ],
    defaultItem: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      userRole: 'user',
      isAdmin: false,
      departmentID: '',
      department: {
        id: '',
        name: ''
      },
      status: 'active'
    },
    editedItem: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      userRole: 'user',
      isAdmin: false,
      departmentID: '',
      department: {
        id: '',
        name: ''
      },
      status: 'active'
    },
    edit: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    filteredList() {
      if (this.currentUser && !this.currentUser.isSuperAdmin) {
        return this._.reject(this.users, function(item) {
          return item.isSuperAdmin
        })
      }
      return this.users || []
    }
  },
  apollo: {
    users: { query: USERS, fetchPolicy: 'network-only' }
  },

  methods: {
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })

        // Transition to the user detail page
        if (id) {
          this.$router.push({
            name: 'UserDetail',
            params: { id: id }
          })
        }
      }
    },

    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },

    deleteItem(item) {
      const index = this.users.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.users.splice(index, 1)
    },

    formatDate(item) {
      return this.$moment(item).format('d MMM YYYY HH:mm')
    },

    calendarDate(item) {
      return (
        this.$moment(item)
          // .format('d MMM YYYY HH:mm')
          .calendar()
      )
    }
  }
  // created () {
  //   this.$moment.locale('nl', null)
  // }
}
</script>

<style></style>
