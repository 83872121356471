<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <!-- <template v-slot:activator="{ on }">
      <v-btn color="primary" dark depressed v-on="on">add user</v-btn>
    </template>-->
    <v-card>
      <v-card-title>
        <span class="headline">New Audit Type</span>
      </v-card-title>

      <v-card-text>
        <div>
          <v-form
            ref="auditFlowTemplateForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="auditType"
                  :items="auditTypes"
                  :rules="requiredRule"
                  label="Audit Type"
                  max-height="auto"
                  autocomplete
                  required
                  item-text="name"
                  item-value="id"
                  :disabled="$apollo.loading"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          rounded
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="info"
          small
          rounded
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AUDIT_FLOW_TEMPLATE_CREATE from '@/graphql/AuditFlowTemplateCreate.gql'
import AUDIT_FLOW_TEMPLATES from '@/graphql/AuditFlowTemplates.gql'
export default {
  name: 'AuditFlowAddDialog',
  props: {
    editedItem: { type: Object, default: null },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    auditTypes: [
      { id: 'BUDGET', name: 'Budget' },
      { id: 'PROJECT', name: 'Project' },
      { id: 'PURCHASEORDER', name: 'Purchase Order' },
      { id: 'PRODUCTIONORDER', name: 'Production Order' },
      { id: 'MANUFACTURINGORDER', name: 'Manufacturing Order' },
      { id: 'SALESORDER', name: 'Sales Order' },
      { id: 'PRODUCT', name: 'Product' },
      { id: 'STYLE', name: 'Style' }
    ],
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    auditType: ''
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        // if (val) {
        //   return true
        // }
        return val
      }
    },
    formTitle() {
      return this.editMode ? 'Edit User' : 'New User'
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    close(success) {
      // if (!this.editMode) {
      //   this.$refs.userForm.reset()
      // }

      this.$emit('close-audit-flow-add-dialog', success)
    },

    save() {
      // Save new item

      let item = {
        auditType: this.auditType,
        from: 0,
        to: 0,
        auditors: []
      }
      // validate
      if (this.$refs.auditFlowTemplateForm.validate()) {
        this.$apollo
          .mutate({
            mutation: AUDIT_FLOW_TEMPLATE_CREATE,
            variables: {
              input: item
            },
            update: (store, { data: { createAuditFlowTemplate } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: AUDIT_FLOW_TEMPLATES
              })
              // Add our requirement from the mutation to the end
              data.auditFlowTemplates.push(createAuditFlowTemplate)
              // Write our data back to the cache.
              store.writeQuery({ query: AUDIT_FLOW_TEMPLATES, data })
            }
          })
          .then(() => {
            this.close(true)
          })
      }
    }
  }
}
</script>

<style></style>
