<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <!-- <template v-slot:activator="{ on }">
      <v-btn color="primary" dark depressed v-on="on">add user</v-btn>
    </template>-->
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text v-if="user">
        <v-container grid-list-md>
          <v-form
            ref="userForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field
                  v-model="user.firstName"
                  :rules="nameRules"
                  label="First Name"
                  required
                  :disabled="$apollo.loading"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="user.lastName"
                  :rules="nameRules"
                  label="Last Name"
                  required
                  :disabled="$apollo.loading"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.email"
                  :rules="emailRules"
                  label="Email"
                  required
                  :disabled="$apollo.loading"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="user.reportsTo"
                  :items="reportsToList"
                  :rules="requiredRule"
                  item-value="id"
                  return-object
                  label="Reports to"
                  max-height="auto"
                  autocomplete
                  required
                  :disabled="$apollo.loading"
                >
                  <template v-slot:item="data">{{
                    data.item.firstName + ' ' + data.item.lastName
                  }}</template>
                  <template v-slot:selection="data">{{
                    data.item.firstName + ' ' + data.item.lastName
                  }}</template>
                  <!-- <template v-slot:item="{ index, data }">
                    <span>{{data.item.firstName + ' ' + data.item.lastName}}</span>
                  </template>-->
                </v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="user.userRole"
                  :items="roles"
                  item-text="name"
                  item-value="name"
                  label="Role"
                  max-height="auto"
                  autocomplete
                  required
                  :disabled="$apollo.loading"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-bold">
                        {{ data.item.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="font-weight-regular caption"
                        >{{ data.item.description }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-if="user.department"
                  v-model="user.department"
                  :items="departments"
                  :rules="nameRules"
                  label="Department"
                  max-height="auto"
                  autocomplete
                  required
                  item-text="name"
                  return-object
                  :disabled="$apollo.loading"
                ></v-select>
              </v-flex>
              <v-flex v-if="user.status !== 'pending'" xs12>
                <v-select
                  v-model="user.status"
                  :items="states"
                  label="Status"
                  max-height="auto"
                  required
                  :disabled="$apollo.loading"
                ></v-select>
              </v-flex>
              <v-flex v-if="!user.isSuperAdmin" xs12>
                <v-checkbox
                  v-model="user.isAdmin"
                  label="Is Admin"
                  max-height="auto"
                  required
                  :disabled="$apollo.loading"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          rounded
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="info"
          small
          rounded
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import USER_LIST from '@/graphql/Users.gql'
import USER_CREATE from '@/graphql/UserCreate.gql'
import USER_UPDATE from '@/graphql/UserUpdate.gql'
import DEPARTMENT_LIST from '@/graphql/Departments.gql'

export default {
  name: 'UserAddDialog',
  props: {
    editedItem: { type: Object, default: null },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    roles: [
      {
        name: 'user',
        description:
          'Normal user has access to assigned department and apps based on roles/rights'
      },
      {
        name: 'projectmanager',
        description:
          'User has access to assigned department and apps based on roles/rights and all departments for projects'
      },
      {
        name: 'manager',
        description:
          'User has access to all assets of a department and apps based on roles/rights'
      },
      {
        name: 'seniormanager',
        description:
          'User has access to assets of all departments and apps based on roles/rights to authorise requests'
      },
      {
        name: 'director',
        description:
          'User has access to assets of all departments and apps based on roles/rights'
      }
    ],
    states: ['active', 'suspended'],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      // v =>
      //   /.+@dailypaperclothing.com\s*$/.test(v) ||
      //   'You can only use your DP email address'
    ],
    nameRules: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredRule: [
      v => !!v.id || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    departments: [],
    users: []
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        // if (val) {
        //   return true
        // }
        return val
      }
    },
    user: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit User' : 'New User'
    },
    reportsToList() {
      return this._.reject(this.users, function(item) {
        return item.isSuperAdmin
      })
    }
  },
  apollo: {
    departments: DEPARTMENT_LIST,
    users: USER_LIST
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    close(success, id) {
      var userID = id || null

      this.$emit('close-user-dialog', success, userID)
    },

    save() {
      if (this.editMode) {
        // Edit item
        let item = {
          id: this.user.id,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          phone: this.user.phone,
          userRole: this.user.userRole,
          reportsToID: this.user.reportsTo.id,
          isAdmin: this.user.isAdmin,
          departmentID: this.user.department.id,
          status: this.user.status
        }
        // validate
        if (this.$refs.userForm.validate()) {
          this.$apollo
            .mutate({
              mutation: USER_UPDATE,
              variables: {
                input: item
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // Save new item
        // validate
        let item = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          phone: this.user.phone,
          userRole: this.user.userRole,
          reportsToID: this.user.reportsTo.id,
          isAdmin: this.user.isAdmin,
          departmentID: this.user.department.id,
          status: this.user.status
        }
        if (this.$refs.userForm.validate()) {
          this.$apollo
            .mutate({
              mutation: USER_CREATE,
              variables: {
                input: item
              },
              update: (store, { data: { createUser } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: USER_LIST
                })
                // Add our requirement from the mutation to the end
                data.users.push(createUser)
                // Write our data back to the cache.
                store.writeQuery({ query: USER_LIST, data })
              }
            })
            .then(response => {
              this.close(true, response.data.createUser.id)
            })
        }
      }
    }
  }
}
</script>

<style></style>
