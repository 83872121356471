<template>
  <div class="page-wrap">
    <div class="page-header pt-0 px-1">
      <v-toolbar color="transparent" flat class="mb-3 b-radius">
        <!-- <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>-->
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" rounded v-on="on">
              <!-- <v-icon class="material-icons-outlined">add</v-icon> -->
              add app
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs6>
                    <v-text-field
                      v-model="editedItem.firstName"
                      label="First Name"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      v-model="editedItem.lastName"
                      label="Last Name"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="editedItem.role"
                      :items="roles"
                      item-text="name"
                      item-value="name"
                      label="Role"
                      max-height="auto"
                      autocomplete
                      return-object
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-bold">{{
                            data.item.name
                          }}</v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="font-weight-regular caption"
                            >{{ data.item.description }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text rounded @click="close"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" small rounded @click="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </div>
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-data-iterator :items="departments" hide-default-footer>
          <template v-slot:item="props">
            <v-layout column>
              <v-flex xs12 mb-3>
                <v-card flat>
                  <v-container fluid>
                    <v-layout align-center justify-center row fill-height px-3>
                      <v-flex xs4>
                        <h4>{{ props.item.name }}</h4>
                      </v-flex>
                      <v-flex xs4>
                        <div class="text-xs-left">
                          <v-layout
                            justify-start
                            row
                            fill-height
                            class="text-uppercase caption grey--text text--lighten-1 mb-1"
                            >members ({{ props.item.members.length }})</v-layout
                          >
                          <v-layout
                            justify-start
                            row
                            fill-height
                            style="min-height: 25px;"
                          >
                            <v-tooltip
                              v-for="item in props.item.members"
                              :key="item.id"
                              bottom
                              open-delay="500"
                            >
                              <template v-slot:activator="{ on }">
                                <span v-on="on">
                                  <avatar
                                    :username="
                                      item.firstName + ' ' + item.lastName
                                    "
                                    :size="25"
                                    color="white"
                                    class="mr-1"
                                    style="user-select: none;"
                                  ></avatar>
                                </span>
                              </template>
                              <span>{{
                                item.firstName + ' ' + item.lastName
                              }}</span>
                            </v-tooltip>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-flex xs4>
                        <div class="justify-end layout">
                          <v-icon
                            small
                            class="mr-2"
                            @click="editItem(props.item)"
                            >edit</v-icon
                          >
                          <v-icon
                            small
                            class="mr-2"
                            @click="editItem(props.item)"
                            >people</v-icon
                          >
                          <v-icon small @click="deleteItem(props.item)"
                            >delete</v-icon
                          >
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </v-data-iterator>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'

export default {
  name: 'IntegrationList',
  components: {
    Avatar
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      { text: 'Members', value: 'members', sortable: false },
      { text: '', sortable: false }
    ],
    departments: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      members: []
    },
    defaultItem: {
      name: '',
      members: []
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Department' : 'Edit Department'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.departments = [
        {
          id: '1',
          name: 'Finance',
          members: [
            {
              id: '1',
              firstName: 'Anne',
              lastName: 'Schrijnwerkers',
              email: 'anne@dailypaperclothing.com',
              role: 'MANAGER'
            }
          ]
        },
        {
          id: '2',
          name: 'Operations',
          members: [
            {
              id: '2',
              firstName: 'Nathalie',
              lastName: 'Robberse',
              email: 'nathalie@dailypaperclothing.com',
              role: 'MANAGER'
            },
            {
              id: '1',
              firstName: 'Anne',
              lastName: 'Schrijnwerkers',
              email: 'anne@dailypaperclothing.com',
              role: 'MEMBER'
            }
          ]
        }
      ]
    },

    removeMember(item) {
      let itemIdx = this.departments[this.editedIndex].members.indexOf(item)
      this.departments[this.editedIndex].members.splice(itemIdx, 1)
    },

    editItem(item) {
      this.editedIndex = this.departments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      const index = this.departments.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.departments.splice(index, 1)
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.departments[this.editedIndex], this.editedItem)
      } else {
        this.departments.push(this.editedItem)
      }
      this.close()
    }
  }
}
</script>

<style></style>
