<template>
  <div class="page-wrap">
    <div class="page-header pt-0 px-1">
      <v-toolbar color="transparent" flat class="mb-3 b-radius">
        <v-dialog v-model="removeAppScopeDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline error--text mb-3"
              >Remove App Scope</v-card-title
            >
            <v-card-text>
              <p>
                This will delete this app scope and user scopes. This can't be
                undone!
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="grey darken-1"
                text
                :disabled="processing"
                @click="removeAppScopeDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                :loading="processing"
                color="error"
                text
                @click.prevent="removeAppScope"
                >Remove</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="scopeDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <div class="headline">{{ editedItem.name }}</div>
            </v-card-title>

            <v-card-text>
              <v-container class="px-0">
                <v-row>
                  <v-col cols="12">
                    <v-label>Scopes</v-label>
                  </v-col>
                </v-row>

                <v-form
                  ref="scopeForm"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row
                    v-for="(item, index) in editedItem.scopes"
                    :key="index"
                  >
                    <v-col cols="4">
                      <v-text-field
                        v-model="item.name"
                        label="name"
                        hide-details
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="item.description"
                        label="description"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        icon
                        color="info"
                        @click="
                          openRemoveAppScopeDialog(item.id, editedItem.id)
                        "
                        ><v-icon>mdi-delete-outline</v-icon></v-btn
                      >
                    </v-col>
                    <v-col cols="12" class="py-1"
                      ><v-divider></v-divider
                    ></v-col>
                  </v-row>
                  <v-flex class="mt-3">
                    <v-btn
                      color="primary"
                      outlined
                      x-small
                      rounded
                      @click="addScopeItem"
                      >add scope</v-btn
                    >
                  </v-flex>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions class="pa-3">
              <v-spacer></v-spacer>
              <v-btn color="secondary lighten-3" text rounded @click="close"
                >Cancel</v-btn
              >
              <v-btn color="primary" small rounded @click="saveScopes"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>-->
        <v-dialog v-model="dialog" persistent max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" rounded v-on="on">
              <!-- <v-icon class="material-icons-outlined">add</v-icon> -->
              add app
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-form
                  ref="appForm"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent
                >
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedItem.name"
                        :rules="nameRule"
                        required
                        label="Name"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        v-model="editedItem.description"
                        rows="3"
                        label="Description"
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedItem.url"
                        :rules="urlRule"
                        required
                        label="Url"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedItem.icon"
                        :rules="iconRule"
                        required
                        label="Icon"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions class="pa-3">
              <v-spacer></v-spacer>
              <v-btn color="secondary lighten-3" text rounded @click="close"
                >Cancel</v-btn
              >
              <v-btn color="primary" rounded small @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-text-field
          v-model="search"
          hide-details
          solo
          flat
          small
          clearable
          placeholder="Search Apps"
          prepend-inner-icon="mdi-magnify"
          class="xs12 sm4 md3 ml-5"
        ></v-text-field>

        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-data-iterator :items="apps" :search="search" hide-default-footer>
          <template v-slot:item="props">
            <v-layout column>
              <v-flex xs12 mb-3>
                <v-card flat>
                  <v-container fluid>
                    <v-layout align-center justify-center row fill-height px-3>
                      <v-flex xs4>
                        <h4>
                          <v-icon
                            color="primary"
                            class="material-icons-outlined"
                            >{{ props.item.icon }}</v-icon
                          >
                          {{ props.item.name }}
                        </h4>
                      </v-flex>
                      <v-flex xs4>
                        <div class="text-xs-left text-truncate">
                          {{ props.item.url }}
                        </div>
                      </v-flex>
                      <v-flex xs4>
                        <div class="justify-end layout">
                          <v-btn icon small class="mr-2">
                            <v-icon small @click="editItem(props.item)"
                              >mdi-pencil-outline</v-icon
                            >
                          </v-btn>
                          <v-btn icon small>
                            <v-icon
                              small
                              class="material-icons-outlined"
                              @click.stop="editScopes(props.item)"
                              >mdi-clipboard-account-outline</v-icon
                            >
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </v-data-iterator>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import APP_LIST from '@/graphql/Apps.gql'
import APP_CREATE from '@/graphql/AppCreate.gql'
import APP_UPDATE from '@/graphql/AppUpdate.gql'
import APP_SCOPE_LIST from '@/graphql/AppScopesByAppID.gql'
import APP_SCOPE_CREATE from '@/graphql/AppScopeCreate.gql'
import APP_SCOPE_UPDATE from '@/graphql/AppScopeUpdate.gql'
import APP_SCOPE_REMOVE from '@/graphql/AppScopeRemove.gql'

export default {
  name: 'AppList',
  mixins: [Vue2Filters.mixin],
  data: () => ({
    processing: false,
    dialog: false,
    removeAppScopeDialog: false,
    removeAppScopeID: null,
    activeAppID: null,
    scopeDialog: false,
    editedIndex: -1,
    valid: true,
    search: '',
    editedItem: {
      name: '',
      description: '',
      url: '',
      icon: '',
      scopes: []
    },
    defaultItem: {
      name: '',
      description: '',
      url: '',
      icon: '',
      scopes: []
    },
    scopeItem: {
      name: '',
      description: ''
    },
    nameRule: [
      v => !!v || 'Name is required',
      v => !/^\s+$/.test(v) || 'Name is required'
    ],
    urlRule: [
      // v => !!v || 'URL is required',
      // v =>
      //   /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
      //     v
      //   ) || 'URL not valid'
      v => !!v || 'URL is required',
      v => /^\/*/.test(v) || 'URL should start with a forward slash'
    ],
    iconRule: [
      v => !!v || 'Icon is required',
      v => !/^\s+$/.test(v) || 'Icon is required'
    ]
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New App' : 'Update App'
    }
  },
  apollo: {
    apps: {
      query: APP_LIST,
      fetchPolicy: 'network-only'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.apps.indexOf(item)
      // if (!item.scopes) {
      //   item.scopes = []
      // }
      this.editedItem = this._.cloneDeep(item)

      this.dialog = true
    },

    editScopes(item) {
      this.editedIndex = this.apps.indexOf(item)

      // Get Scopes
      this.$apollo
        .query({
          query: APP_SCOPE_LIST,
          variables: {
            appID: item.id
          }
        })
        .then(result => {
          if (result.data.appScopesByAppID.length === 0) {
            item.scopes = []
          } else {
            item.scopes = result.data.appScopesByAppID
          }

          this.editedItem = this._.cloneDeep(item)

          this.scopeDialog = true
        })
    },

    deleteItem(item) {
      const index = this.departments.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.departments.splice(index, 1)
    },

    close() {
      this.dialog = false
      this.scopeDialog = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editedIndex = -1
    },
    addScopeItem() {
      let item = this._.cloneDeep(this.scopeItem)
      this.editedItem.scopes.push(item)
    },

    save() {
      if (this.editedIndex > -1) {
        // Update
        // validate
        if (this.$refs.appForm.validate()) {
          this.$apollo
            .mutate({
              mutation: APP_UPDATE,
              variables: {
                input: {
                  id: this.editedItem.id,
                  name: this.editedItem.name,
                  url: this.editedItem.url,
                  icon: this.editedItem.icon,
                  description: this.editedItem.description
                }
              }
            })
            .then(() => {
              this.close()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'App updated'
              })
            })
        }
      } else {
        // Create
        delete this.editedItem.scopes
        // validate
        if (this.$refs.appForm.validate()) {
          this.$apollo
            .mutate({
              mutation: APP_CREATE,
              variables: {
                input: this.editedItem
              },
              update: (store, { data: { createApp } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: APP_LIST,
                  variables: { id: createApp.id }
                })
                // Add our requirement from the mutation to the end
                data.apps.push(createApp)
                // Write our data back to the cache.
                store.writeQuery({ query: APP_LIST, data })
              }
            })
            .then(() => {
              this.close()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'App created'
              })
            })
        }
      }
    },
    saveScopes() {
      let updateItems = []
      let newItems = []
      let thus = this

      this._.forEach(this.editedItem.scopes, function(item) {
        if (item.id) {
          let newItem = {
            id: item.id,
            appID: item.appID,
            name: item.name,
            description: item.description
          }
          updateItems.push(newItem)
        } else {
          item.appID = thus.editedItem.id
          newItems.push(item)
        }
      })

      if (newItems.length > 0) {
        // Add new items
        if (this.$refs.scopeForm.validate()) {
          this.$apollo
            .mutate({
              mutation: APP_SCOPE_CREATE,
              variables: {
                input: newItems
              },
              update: (store, { data: { createAppScopes } }) => {
                // Read the data from our cache for this query.
                // const data = store.readQuery({
                //   query: APP_SCOPE_LIST,
                //   variables: { appID: createAppScopes[0].appID }
                // })

                // Force the app list to update
                const data = store.readQuery({
                  query: APP_LIST
                })

                let idx = thus._.findIndex(data.apps, {
                  id: createAppScopes[0].appID
                })

                thus._.forEach(createAppScopes, function(item) {
                  data.apps[idx].scopes.push(item)
                })

                // Add our requirement from the mutation to the end
                // data.appScopesByAppID.push(createAppScopes)
                // Write our data back to the cache.
                // store.writeQuery({ query: APP_SCOPE_LIST, data })

                store.writeQuery({
                  query: APP_LIST,
                  data
                })
              }
            })
            .then(() => {
              this.$apollo.queries.apps.refetch()
              this.close()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'New items added'
              })
            })
        }
      }

      if (updateItems.length > 0) {
        // Update items
        if (this.$refs.scopeForm.validate()) {
          this.$apollo
            .mutate({
              mutation: APP_SCOPE_UPDATE,
              variables: {
                input: updateItems
              }
              // update: (store, { data: { updateAppScopes } }) => {
              //   // Read the data from our cache for this query.
              //   const data = store.readQuery({
              //     query: APP_SCOPE_LIST,
              //     variables: { appID: updateAppScopes[0].appID }
              //   })
              //   // Add our requirement from the mutation to the end
              //   data.appScopesByAppID.push(updateAppScopes)
              //   // Write our data back to the cache.
              //   store.writeQuery({ query: APP_SCOPE_LIST, data })
              // }
            })
            .then(() => {
              this.close()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Items updated'
              })
            })
        }
      }
    },

    openRemoveAppScopeDialog(scopeID, appID) {
      this.removeAppScopeID = scopeID
      this.activeAppID = appID
      this.removeAppScopeDialog = true
    },
    removeAppScope() {
      var vm = this

      this.processing = true

      this.$apollo
        .mutate({
          mutation: APP_SCOPE_REMOVE,
          variables: {
            id: vm.removeAppScopeID
          },
          update: (store, { data: { removeAppScope } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: APP_LIST
            })

            var appIdx = vm._.findIndex(data.apps, function(item) {
              return item.id === vm.activeAppID
            })

            if (appIdx > -1) {
              var idx = vm._.findIndex(data.apps[appIdx].scopes, function(
                item
              ) {
                return item.id === removeAppScope
              })
            }

            // Remove item from the dialog
            if (idx > -1) vm.editedItem.scopes.splice(idx, 1)

            // remove our requirement from the mutation
            if (idx > -1) data.apps[appIdx].scopes.splice(idx, 1)
            // Write our data back to the cache.
            store.writeQuery({
              query: APP_LIST,
              data
            })
          }
        })
        .then(() => {
          this.$apollo.queries.apps.refetch()
          this.processing = false
          this.removeAppScopeID = null
          this.activeAppID = null
          this.removeAppScopeDialog = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Scope Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
