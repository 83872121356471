<template>
  <div class="page-wrap">
    <div class="page-header pt-0 px-1">
      <v-toolbar color="transparent" flat class="mb-3 b-radius">
        <!-- <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>-->
        <v-dialog v-model="dialog" max-width="500px" persistent>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" rounded v-on="on">
              <!-- <v-icon class="material-icons-outlined">add</v-icon> -->
              add department
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-form
              ref="departmentForm"
              v-model="valid"
              lazy-validation
              @submit.prevent
            >
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        :rules="nameRule"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary lighten-2" text @click="close"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-text-field
          v-model="search"
          hide-details
          solo
          flat
          small
          clearable
          placeholder="Search Department"
          prepend-inner-icon="mdi-magnify"
          class="xs12 sm4 md3 ml-5"
        ></v-text-field>

        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-data-iterator
          v-if="departments"
          :items="departments"
          :search="search"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item="props">
            <v-layout column>
              <v-flex xs12 mb-3>
                <v-card flat>
                  <v-container fluid>
                    <v-layout align-center justify-center row fill-height px-3>
                      <v-flex xs4>
                        <h4>{{ props.item.name }}</h4>
                      </v-flex>
                      <v-flex xs4>
                        <div class="text-xs-left">
                          <!-- <v-layout
                            justify-start
                            row
                            fill-height
                            class="text-uppercase overline grey--text text--lighten-1 mb-1"
                            style
                          >members ({{props.item.members.length}})</v-layout>-->
                          <v-layout
                            v-if="props.item.members"
                            justify-start
                            row
                            fill-height
                            style="min-height: 25px;"
                          >
                            <v-tooltip
                              v-for="item in props.item.members.slice(0, 5)"
                              :key="item.id"
                              bottom
                              open-delay="500"
                            >
                              <template v-slot:activator="{ on }">
                                <span v-on="on">
                                  <avatar
                                    :username="
                                      item.firstName + ' ' + item.lastName
                                    "
                                    :size="25"
                                    color="white"
                                    class="mr-1"
                                    style="user-select: none;"
                                  ></avatar>
                                </span>
                              </template>
                              <span>{{
                                item.firstName + ' ' + item.lastName
                              }}</span>
                            </v-tooltip>
                            <avatar
                              v-if="props.item.members.length > 5"
                              :username="'+ ' + (props.item.members.length - 5)"
                              :size="25"
                              color="white"
                              background-color="#aaa"
                              class="mr-1"
                              style="user-select: none;"
                            ></avatar>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-flex xs4>
                        <div class="justify-end layout">
                          <v-btn
                            small
                            icon
                            class="mr-2"
                            @click="editItem(props.item)"
                          >
                            <v-icon small>mdi-pencil-outline</v-icon>
                          </v-btn>
                          <!-- <department-members-modal
                            :department="props.item"
                            @department-members-added="membersAdded"
                          ></department-members-modal>
                          <v-btn small icon @click="deleteItem(props.item)">
                            <v-icon class="material-icons-outlined" small>delete</v-icon>
                          </v-btn>-->
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </v-data-iterator>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'
import DEPARTMENT_LIST from '@/graphql/Departments.gql'
import DEPARTMENT_CREATE from '@/graphql/DepartmentCreate.gql'
import DEPARTMENT_UPDATE from '@/graphql/DepartmentUpdate.gql'

export default {
  name: 'DepartmentList',
  components: {
    Avatar
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      { text: 'Members', value: 'members', sortable: false },
      { text: '', sortable: false }
    ],
    search: null,
    edit: false,
    editedItem: {
      name: '',
      members: []
    },
    defaultItem: {
      name: '',
      members: []
    },

    valid: true,
    nameRule: [
      v => !!v || 'Name is required',
      v => !/^\s+$/.test(v) || 'Name is required'
    ]
  }),

  apollo: {
    departments: {
      query: DEPARTMENT_LIST,
      fetchPolicy: 'cache-and-network' // "cache-first" | "cache-and-network" | "network-only" | "cache-only" | "no-cache" | "standby"
    }
  },

  computed: {
    formTitle() {
      return this.edit ? 'Edit Department' : 'New Department'
    }
  },

  // watch: {
  //   dialog (val) {
  //     val || this.close()
  //   }
  // },

  methods: {
    removeMember() {},

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },

    deleteItem(item) {
      const index = this.departments.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.departments.splice(index, 1)
    },

    close() {
      this.dialog = false

      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.edit = false
    },

    save() {
      if (this.edit) {
        let data = {
          id: this.editedItem.id,
          name: this.editedItem.name
        }
        // Edit
        if (this.$refs.departmentForm.validate()) {
          this.$apollo
            .mutate({
              mutation: DEPARTMENT_UPDATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close()

              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Department Updated'
              })
            })
        }
      } else {
        let data = {
          name: this.editedItem.name
        }
        // save
        if (this.$refs.departmentForm.validate()) {
          this.$apollo
            .mutate({
              mutation: DEPARTMENT_CREATE,
              variables: {
                input: data
              },
              update: (store, { data: { createDepartment } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: DEPARTMENT_LIST
                })
                // Add our requirement from the mutation to the end
                data.departments.push(createDepartment)
                // Write our data back to the cache.
                store.writeQuery({ query: DEPARTMENT_LIST, data })
              }
            })
            .then(() => {
              this.close()

              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Department Created'
              })
            })
        }
      }
      this.close()
    },
    membersAdded() {
      this.$store.commit('showSnackbar', {
        active: true,
        message: 'Members Added'
      })
    }
  }
}
</script>

<style></style>
