<template>
  <div style="height: 100%;">
    <main-navigation nav-drawar-color="light-theme" light>
      <template v-slot:nav-items>
        <v-list-item
          v-if="activeScope('users')"
          :to="{ name: 'UserList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('departments')"
          :to="{ name: 'DepartmentList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Departments</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="activeScope('audit_flows')"
          :to="{ name: 'AuditFlows' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-message-draw</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Audit Flows</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="currentUser && currentUser.isSuperAdmin"
          :to="{ name: 'AppList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-apps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Apps</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="currentUser && currentUser.isSuperAdmin"
          :to="{ name: 'ErrorList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-alert-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Errors</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </main-navigation>

    <div class="app-layout">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainNavigation from '@/components/core/MainNavigation.vue'

export default {
  name: 'AdminLayout',
  components: {
    MainNavigation
  },
  data: () => ({
    drawer: true,
    loggedInUser: {
      firstName: 'Stan',
      lastName: 'Nok'
    }
  }),
  apollo: {},
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    userScopes() {
      return this.$store.getters.userScopes
    }
  },
  created() {
    this.setTheme()
  },
  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = '#002447'
      this.$vuetify.theme.themes.light.secondary = '#53d3d1'
      this.$vuetify.theme.themes.light.accent = '#fbeceb'
      this.$vuetify.theme.themes.light.error = '#FF5252'
      this.$vuetify.theme.themes.light.info = '#2196F3'
      this.$vuetify.theme.themes.light.success = '#4CAF50'
      this.$vuetify.theme.themes.light.warning = '#FFC107'
    },
    activeScope(scope) {
      var idx = this._.findIndex(this.userScopes, function(item) {
        return item.name === scope && item.accessLevel > 0
      })
      if (idx > -1) {
        return true
      }

      return false
    }
  }
}
</script>

<style></style>
