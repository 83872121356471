var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header pt-0 px-1"},[_c('v-toolbar',{staticClass:"mb-3 b-radius",attrs:{"color":"transparent","flat":""}},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.newItem}},[_vm._v("add user")]),_c('user-add-dialog',{attrs:{"open":_vm.dialog,"edited-item":_vm.editedItem,"edit-mode":_vm.edit},on:{"close-user-dialog":_vm.close}}),_c('v-text-field',{staticClass:"xs12 sm4 md3 ml-5",attrs:{"hide-details":"","solo":"","flat":"","dense":"","clearable":"","placeholder":"Search User","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)],1),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-card',{staticClass:"b-radius pa-3",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-light",attrs:{"headers":_vm.headers,"items":_vm.filteredList,"search":_vm.search,"items-per-page":_vm.pageLength,"hide-default-footer":_vm.filteredList.length < _vm.pageLength,"mobile-breakpoint":null},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"fancy-link text-truncate",attrs:{"to":{ name: 'UserDetail', params: { id: item.id } }}},[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}},{key:"item.isAdmin",fn:function(ref){
var item = ref.item;
return [(item.isAdmin)?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"info lighten-2 font-weight-bold upperCaseSpacing","x-small":""}},[_vm._v("admin")]):_vm._e(),(item.isSuperAdmin)?_c('v-chip',{attrs:{"color":"warning font-weight-bold upperCaseSpacing","x-small":""}},[_vm._v("superadmin")]):_vm._e()]}},{key:"item.lastName",fn:function(ref){return undefined}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [(item.lastLogin)?_c('span',{staticClass:" text-truncate"},[_vm._v(_vm._s(_vm.calendarDate(item.lastLogin)))]):_c('span',[_vm._v("never")])]}},{key:"item.reportsTo",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"fancy-link text-truncate",attrs:{"to":{ name: 'UserDetail', params: { id: item.reportsTo.id } }}},[_vm._v(_vm._s(item.reportsTo.firstName + ' ' + item.reportsTo.lastName))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" "),(item.status === 'suspended')?_c('span',[_vm._v("("+_vm._s(_vm.formatDate(item.updatedAt))+")")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"grey","rounded":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" quick edit ")])]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }