<template>
  <div class="page-wrap">
    <div class="page-header pt-0 px-1">
      <v-toolbar color="transparent" flat class="mb-3 b-radius">
        <v-btn color="primary" rounded @click="newItem">add error code</v-btn>
        <v-dialog v-model="dialog" persistent max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ title }} Error Code</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-form
                  ref="errorCodeForm"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.errorCode"
                        :rules="requiredRule"
                        label="Error Code"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.service"
                        :items="services"
                        :rules="requiredRule"
                        label="Service"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="pb-1">
                      <label>Cause</label>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <!-- <v-text-field
                        v-model="editedItem.cause"
                        :rules="requiredRule"
                        label="Cause"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field> -->
                      <vue-editor
                        v-model="editedItem.cause"
                        class="editor-custom"
                        :editor-options="editorConfig"
                        placeholder="Describe the cause"
                        :rules="requiredRule"
                      />
                    </v-col>
                    <v-col cols="12" class="pb-1">
                      <label>Resolution</label>
                    </v-col>
                    <v-col cols="12" class="pt-1">
                      <!-- <v-text-field
                        v-model="editedItem.resolution"
                        :rules="requiredRule"
                        label="Resolution"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field> -->
                      <vue-editor
                        v-model="editedItem.resolution"
                        class="editor-custom"
                        :editor-options="editorConfig"
                        placeholder="Describe the resolution"
                        :rules="requiredRule"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions class="pa-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                rounded
                :disabled="$apollo.loading"
                @click="close(false)"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                rounded
                :loading="$apollo.loading"
                @click="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- <v-text-field
          v-model="search"
          hide-details
          solo
          flat
          dense
          clearable
          placeholder="Search ErrorCode"
          prepend-inner-icon="mdi-magnify"
          class="xs12 sm4 md3 ml-5"
        ></v-text-field> -->

        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-card class="b-radius pa-3" flat>
          <v-data-table
            :headers="headers"
            :items="errorCodes"
            :search="search"
            :mobile-breakpoint="null"
            class="table-light"
          >
            <template v-slot:item.cause="data">
              <div v-html="data.item.cause"></div>
            </template>
            <template v-slot:item.resolution="data">
              <div v-html="data.item.resolution"></div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                color="info"
                text
                rounded
                x-small
                class="mr-2"
                @click.stop="editItem(item)"
              >
                view
              </v-btn>
              <v-btn
                color="grey"
                text
                rounded
                x-small
                @click.stop="deleteItem(item)"
              >
                remove
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import ERROR_CODES from '@/graphql/ErrorCodes.gql'
import ERROR_CODE_CREATE from '@/graphql/ErrorCodeCreate.gql'
import ERROR_CODE_UPDATE from '@/graphql/ErrorCodeUpdate.gql'
import ERROR_CODE_REMOVE from '@/graphql/ErrorCodeRemove.gql'

export default {
  name: 'ErrorList',

  components: {},
  data: () => ({
    pageLength: 10,
    dialog: false,
    editMode: false,
    search: '',
    defaultItem: {
      errorCode: '',
      service: '',
      cause: '',
      resolution: ''
    },
    editedItem: {
      errorCode: '',
      service: '',
      cause: '',
      resolution: ''
    },
    valid: true,
    services: ['API', 'LOGISTICS', 'SALES', 'PROMESE', 'BLECKMANN', 'SHOPIFY'],
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    editorConfig: {
      modules: {
        toolbar: false
      }
    }
  }),
  computed: {
    title() {
      if (this.editMode) {
        return 'Update '
      } else {
        return 'Create '
      }
    },
    headers: () => {
      return [
        {
          text: 'Code',
          align: 'left',
          sortable: true,
          value: 'errorCode'
        },
        { text: 'Service', align: 'left', value: 'service', sortable: true },
        {
          text: 'Cause',
          align: 'left',
          value: 'cause',
          sortable: false
        },
        {
          text: 'Resolution',
          align: 'left',
          value: 'resolution',
          sortable: false
        },
        {
          text: '',
          align: 'right',
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  apollo: {
    errorCodes: { query: ERROR_CODES, fetchPolicy: 'network-only' }
  },

  methods: {
    close() {
      this.dialog = false
    },

    newItem() {
      this.editMode = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.editMode = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },

    deleteItem(item) {
      const index = this.errorCodes.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.$apollo
          .mutate({
            mutation: ERROR_CODE_REMOVE,
            variables: {
              id: item.errorCode
            }
          })
          .then(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Changes Saved'
            })
            this.errorCodes.splice(index, 1)
            this.close(true)
          })
    },
    save() {
      let data = {
        errorCode: this.editedItem.errorCode,
        service: this.editedItem.service,
        cause: this.editedItem.cause,
        resolution: this.editedItem.resolution
      }
      // validate
      if (this.$refs.errorCodeForm.validate()) {
        if (this.editMode) {
          this.$apollo
            .mutate({
              mutation: ERROR_CODE_UPDATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Changes Saved'
              })
              this.$apollo.queries.errorCodes.refetch()
              this.close(true)
            })
        } else {
          this.$apollo
            .mutate({
              mutation: ERROR_CODE_CREATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Changes Saved'
              })
              this.$apollo.queries.errorCodes.refetch()
              this.close(true)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-custom {
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }

  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
