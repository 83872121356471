<template>
  <div class="page-wrap">
    <audit-flow-add-dialog
      :open="newDialog"
      @close-audit-flow-add-dialog="close"
    ></audit-flow-add-dialog>
    <audit-flow-level-edit-dialog
      :item="levelEditedItem"
      :open="levelDialog"
      :edit="edit"
      @close-audit-flow-level-dialog="close"
    ></audit-flow-level-edit-dialog>
    <v-dialog v-model="auditLevelDeleteDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Audit Level</v-card-title
        >
        <v-card-text>
          <p>
            Are you sure your want to delete this audit level.
          </p>

          <p>
            All deletions are final and can't be reversed.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey"
            text
            rounded
            @click="auditLevelDeleteDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text rounded @click="removeAuditLevel"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="currentUser && currentUser.isSuperAdmin" class="page-header">
      <v-btn color="primary" rounded @click="addAuditType"
        >New Audit Type</v-btn
      >
    </div>

    <div class="main-page-column pt-0" style="width: 100%">
      <div class="scroll-container pt-0">
        <v-row justify="center">
          <v-col xs="12" sm="12" md="10" lg="10" xl="8">
            <div class="main-container pt-0">
              <!-- <audit-flow-sections
                v-if="auditFlowTemplates"
                :items="auditFlowTemplates"
              ></audit-flow-sections> -->

              <v-row
                v-for="level in groupedList"
                :key="level.name"
                class="mb-8"
              >
                <v-col cols="12">
                  <v-row class="mb-2">
                    <h1
                      class="title grey--text text--darken-1 font-weight-regular"
                    >
                      {{ title(level.name) }}
                    </h1>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey lighten-1"
                      outlined
                      rounded
                      x-small
                      @click="newLevel(level.name)"
                      >add level</v-btn
                    >
                  </v-row>
                  <v-row>
                    <v-divider></v-divider>
                  </v-row>
                </v-col>
                <v-col
                  v-for="item in level.data"
                  :key="item.id"
                  cols="12"
                  class="px-0"
                >
                  <v-card flat>
                    <v-row class="px-3">
                      <v-col v-if="defaultCurrency"
                        >From:
                        {{
                          (item.from / 100)
                            | currency(defaultCurrency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}
                      </v-col>
                      <v-col v-if="item.to && item.to > 0 && defaultCurrency"
                        >To:
                        {{
                          (item.to / 100)
                            | currency(defaultCurrency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col
                      >
                      <v-spacer></v-spacer>
                      <v-col>
                        <v-row justify="end" class="px-3">
                          <v-btn
                            rounded
                            outlined
                            x-small
                            color="info"
                            @click="editLevel(item)"
                            >edit</v-btn
                          >
                          <v-btn
                            v-if="level.data.length > 1"
                            rounded
                            outlined
                            x-small
                            color="grey"
                            class="ml-2"
                            @click="confirmAuditLevelDelete(item)"
                            >delete</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-card-text>
                      <v-row v-for="auditor in item.auditors" :key="auditor.id">
                        <v-col cols="12" class="py-0">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="1" class="pt-3">{{
                          auditor.sequence
                        }}</v-col>
                        <v-col cols="11">{{
                          auditor.firstName + ' ' + auditor.lastName
                        }}</v-col>
                        <!-- <v-col cols="2">
                          <v-btn absolute right x-small fab elevation="0">
                            <v-icon x-small class="material-icons-outlined">edit</v-icon>
                          </v-btn>
                        </v-col>-->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import AuditFlowAddDialog from '@/modules/admin/components/core/AuditFlowAddDialog.vue'
import AuditFlowLevelEditDialog from '@/modules/admin/components/core/AuditFlowLevelEditDialog.vue'
// import EditAuthFlowTemplate from '@/modules/finance/components/core/EditAuthFlowTemplate.vue'
import AUDIT_FLOW_TEMPLATES from '@/graphql/AuditFlowTemplates.gql'
import REMOVE_AUDIT_FLOW_TEMPLATE from '@/graphql/AuditFlowTemplateRemove.gql'
import USERS from '@/graphql/Users.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'AuditFlows',
  components: {
    AuditFlowAddDialog,
    AuditFlowLevelEditDialog
  },
  data: () => ({
    search: '',
    appUsers: [],
    dialog: false,
    auditLevelDeleteDialog: false,
    levelDialog: false,
    newDialog: false,
    defaultItem: {
      id: '',
      userID: '',
      firstName: '',
      lastName: '',
      email: '',
      appID: '',
      auditors: [],
      status: 'active',
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      userID: '',
      firstName: '',
      lastName: '',
      email: '',
      appID: '',
      auditors: [],
      status: 'active',
      updatedAt: new Date(),
      createdAt: new Date()
    },
    levelDefaultItem: {
      id: '',
      auditType: '',
      from: 0,
      to: 0,
      auditors: []
    },
    levelEditedItem: {
      id: '',
      auditType: '',
      from: 0,
      to: 0,
      auditors: []
    },
    deleteItem: '',
    edit: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    groupedList() {
      return this._.chain(this.auditFlowTemplates)
        .groupBy('auditType')
        .map((value, key) => ({ name: key, data: value }))
        .value()
    },
    defaultCurrency() {
      return this._.find(this.currencies, function(item) {
        return item.defaultCurrency === true
      })
    }
    // currentApp () {
    //   return this.$store.state.currentApp
    // },
    // configureUsers () {
    //   let lookup = this._.keyBy(this.authFlowTemplatesByAppID, function (o) {
    //     return o.userID
    //   })
    //   return this._.filter(this.appUsers, function (item) {
    //     return lookup[item.id] === undefined
    //   })
    // },
    // authLevel () {
    //   return this.$store.getters.authLevel('finance_settings')
    // }
  },
  apollo: {
    users: USERS,
    auditFlowTemplates: AUDIT_FLOW_TEMPLATES,
    currencies: CURRENCY_LIST
  },
  methods: {
    title(type) {
      switch (type) {
        case 'BUDGET':
          return 'Budget'

        case 'PROJECT':
          return 'Project'

        case 'PURCHASEORDER':
          return 'Purchase Order'

        case 'PRODUCTIONORDER':
          return 'Production Order'

        case 'MANUFACTURINGORDER':
          return 'Manufacturing Order'

        case 'SALESORDER':
          return 'Sales order'

        case 'PRODUCT':
          return 'Product'

        case 'STYLE':
          return 'Style'

        default:
          break
      }
    },
    addAuditType() {
      this.newDialog = true
    },
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      this.levelDialog = false
      this.newDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editedItem.appID = this.currentApp.id
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },

    newLevel(auditType) {
      this.edit = false
      this.levelEditedItem = this._.cloneDeep(this.levelDefaultItem)
      this.levelEditedItem.auditType = auditType
      this.levelDialog = true
    },

    editLevel(item) {
      this.edit = true
      var data = {
        id: item.id,
        auditType: item.auditType,
        from: item.from / 100,
        to: item.to / 100,
        auditors: item.auditors
      }
      if (!item.auditors) {
        this.data.auditors = []
      }
      this.levelEditedItem = this._.cloneDeep(data)
      this.levelDialog = true
    },

    confirmAuditLevelDelete(item) {
      this.deleteItem = item
      this.auditLevelDeleteDialog = true
    },

    removeAuditLevel() {
      var that = this
      this.$apollo
        .mutate({
          mutation: REMOVE_AUDIT_FLOW_TEMPLATE,
          variables: {
            id: that.deleteItem.id
          },
          update: (store, { data: { removeAuditFlowTemplate } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: AUDIT_FLOW_TEMPLATES
            })

            var idx = that._.findIndex(data.auditFlowTemplates, function(item) {
              return item.id === removeAuditFlowTemplate
            })

            data.auditFlowTemplates.splice(idx, 1)

            // Add our requirement from the mutation to the end
            // data.budgetCategory.lineItems.push(createBudgetLine)
            // Write our data back to the cache.
            store.writeQuery({
              query: AUDIT_FLOW_TEMPLATES,
              data
            })
          }
        })
        .then(() => {
          this.auditLevelDeleteDialog = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Item Removed'
          })
          this.deleteItem = ''
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrap {
  --page-header-height: 50px;
  // --page-wrap-offset: -20px;
  &.sub-header {
    --page-header-height: 0px;
  }
}
</style>
